<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="8"
      >
        <base-material-card
          title="Informations jante"
        >
          <template>
            <u> Informations </u> :
            <v-row justify="center">
              <v-col
                cols="12"
                md="6"
              >
                <div class="text-h3 font-weight-light">
                  <v-text-field
                    v-model="infoJante.marque"
                    label="Marque"
                    readonly
                  />
                </div>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <div class="text-h3 font-weight-light">
                  <v-text-field
                    v-model="infoJante.modele"
                    label="Modele"
                    readonly
                  />
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <div class="text-h3 font-weight-light">
                  <v-text-field
                    v-model="infoJante.diametre"
                    label="Diametre"
                    readonly
                  />
                </div>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <div class="text-h3 font-weight-light">
                  <v-text-field
                    v-model="infoJante.price"
                    label="Prix"
                    readonly
                  />
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <div class="text-h3 font-weight-light">
                  <v-text-field
                    v-model="infoJante.stock"
                    type="number"
                    label="Stock"
                  />
                </div>
              </v-col>
            </v-row>
            <u> Caractéristiques </u> :
            <div class="text-h3 font-weight-light">
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <div class="text-h3 font-weight-light">
                    <v-text-field
                      v-model="infoJante.offset"
                      label="Offset"
                      readonly
                    />
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <div class="text-h3 font-weight-light">
                    <v-text-field
                      v-model="infoJante.largeur"
                      label="Largeur"
                      readonly
                    />
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <div class="text-h3 font-weight-light">
                    <v-text-field
                      v-model="infoJante.alesage"
                      label="Alesage"
                      readonly
                    />
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <div class="text-h3 font-weight-light">
                    <v-text-field
                      v-model="infoJante.entraxe"
                      label="Entraxe"
                      readonly
                    />
                  </div>
                </v-col>
              </v-row>
            </div>
          </template>
        </base-material-card>
      </v-col>

      <v-col
        cols="12"
        md="4"
      >
        <base-material-card
          title="Actions"
        >
          <v-card-text>
            <v-row>
              <v-btn
                color="edit"
                rounded
                class="mr-0"
                @click="ModifierStock(infoJante)"
              >
                Valider le stock
              </v-btn>
              <v-btn
                color="error"
                rounded
                class="mr-0"
                @click="SupprimerJantes(infoJante)"
              >
                Supprimer
              </v-btn>
            </v-row>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="8"
      >
        <base-material-card
          title="Images"
        >
          <img :src="infoJante.photo_1">
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  // Utilities
  import axios from 'axios'
  import VueAxios from 'vue-axios'
  import Vue from 'vue'
  import {
    mapState,
  } from 'vuex'

  Vue.use(VueAxios, axios)

  export default {
    name: 'DashboardCoreDrawer',

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      infoJante: '',
      textRetirer: 'Retirer du Catalogue',
      statutItem: 'Publié',
      affichage: false,
    }),
    computed: {
      ...mapState(['barColor', 'barImage']),
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },
      computedItems () {
        return this.items.map(this.mapItem)
      },
      profile () {
        return {
          avatar: true,
          title: this.$t('site_title'),
        }
      },
    },
    created () {
      this.infoJante = this.$route.params.infoJantes
      if (this.$route.params.infoJantes.visible === 0) {
        this.statutItem = 'Non publié'
      }
    },
    methods: {
      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: this.$t(item.title),
        }
      },
      ModifierStock: function (item) {
        console.log(item)
        // Modification d'un produit
        Vue.axios.put('https://api.espace-jantes.com/updateStockJante', {
          params: { infoJante: item },
        })
          .then((response) => {
            if (response.data.error) {
              // Erreur
              // Faire gestion de l'erreur
            }
            // Notification
            console.log(response.data.message)
          })
          .catch((error) => {
            console.log(error)
          })
      },
      SupprimerJantes: function (item) {
        Vue.axios.put('https://api.espace-jantes.com/supprimerJante', {
          params: { infoJante: item },
        })
          .then((response) => {
            var self = this
            setTimeout(function () { self.$router.go(0) }, 2000)
          })
          .catch(error => console.log(error))
      },
      publication: function () {
        if (this.affichage === false) {
          this.affichage = true
        } else {
          this.affichage = false
        }
      },
    },
  }
</script>
